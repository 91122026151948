.container {
    position: relative;
    overflow-x: hidden;
    padding: 0 100px;
}
.container .backGrad {
    z-index: -2;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
    background-image: linear-gradient( #230D25,#4C0153);
    
}
.backdesign {
    position: fixed;
    top: 0;
    right: -90px;
    transform: rotate(-30deg);
    opacity: .4;
    z-index: -1;
}

.hero{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
align-items: center;
}
.hero img {
    height: 300px;
    margin-right: 40px;
}
.hero .content h1{
    font-size: 42px;
}
.hero .content p {
    font-size: 18px;
}

.gallery {
    padding: 100px 0;
}
.gallery h1 {
    margin-bottom: 50px;
}
.slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.images {
    display: flex;
}
.imageCard {
    margin: 0 30px;
    height: 350px;
    width: 350px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    overflow: hidden;
}
.imageCard img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.controlButtons {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.controlButtons img {
    margin: 30px 0;
}


.questions{
    background-color: black;
    border: 2px solid #555555;
    padding: 25px;
    border-radius: 50px;
    margin-bottom: 50px;
    height: 200px;
    overflow-y: hidden;
    position: relative;
    transition: all 1s linear;
}
.questions .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000000b0;
    height: 100%;
    width: 100%;
    transition: all 1s linear;
}
.questions h1 {
        font-family: 'kunkun';
    font-weight: 600;
}
.questions p {
    font-family: 'kunkun';
    font-weight: 400;
}