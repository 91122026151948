@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@font-face {
  font-family: 'begum tamil';
  src: url("/public/assets/fonts/begum\ regular.OTF");
  font-weight: 500;
}
@font-face {
  font-family: 'begum tamil';
  src: url("/public/assets/fonts/begum\ bold.OTF");
  font-weight: 800;
}
@font-face {
  font-family: 'begum tamil';
  src: url("/public/assets/fonts/begum\ semibold.OTF");
  font-weight: 600;
}
@font-face {
  font-family: 'begum tamil';
  src: url("/public/assets/fonts/begum\ light.OTF");
  font-weight: 400;
}
@font-face {
  font-family: 'kunkun';
  src: url("/public/assets/fonts/kunkun\ medium.OTF");
  font-weight: 400;
}
@font-face {
  font-family: 'kunkun';
  src: url('/public/assets/fonts/kunkun\ bold.OTF');
  font-weight: 600;
}

*{
  font-family: 'begum tamil';
  color: white;
}