.container{
    position: fixed;
    background-color: #230D25;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
      transition: all 0.3s; /* Transition effect when sliding down (and up) */
}
.logo{
    display: flex;
    align-items: center;
}
.logo span{
    font-size: 32.8px;
    font-weight: bold;
}
.logo img {
    height: 45px;
    margin-left: 30px;
}

.container .links {
    padding-right: 20px;
    color: white;
}
.container .links li {
    font-family: 'Noto Sans';
    font-size: 20px;
    display: inline-block;
    margin: 0 20px;
    color: white;
    transition: all .1s cubic-bezier(0.075, 0.82, 0.165, 1);
    cursor: pointer;
}
.container  li:hover {
    transform: translate(1px,2px);
    font-weight: 500;
    text-shadow: 3px 3px black;
}