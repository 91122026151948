.container {
    position: relative;
    overflow-x: hidden;
    padding: 100px;
}
.container .backGrad {
    z-index: -2;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
    background-image: linear-gradient( #230D25,#4C0153);
    
}
.backdesign {
    position: fixed;
    top: 0;
    right: -90px;
    transform: rotate(-30deg);
    opacity: .4;
    z-index: -1;
}
.images{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container h1 {
    font-size: 42px;
    margin: 0;
}
.controlButtons {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
}
.controlButtons img {
    margin: 30px 0;
    cursor: pointer;
}