.container {
    position: relative;
    overflow-x: hidden;
}
.container .backGrad {
    z-index: -2;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
    background-image: linear-gradient( #230D25,#4C0153);
    
}
.backdesign {
    position: fixed;
    top: 0;
    right: -90px;
    transform: rotate(-30deg);
    opacity: .4;
    z-index: -1;
}

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    color: white;
    height: 100vh;
    padding-right: 100px;
    padding-left: 100px;
}
.hero h4 {
    width: 80%;
    font-size: 38px;
    margin: 10px 0;
}
.hero p {
    width: 65%;
    font-size: 30px;
    margin: 10px 0
}


.banner{
    display: flex;
    margin: 30px 100px;
    margin-top: 50px;
    padding: 30px 30px;
    border: 1px solid white;
    border-radius: 50px;
    background-color: #000000b8;
    cursor: pointer;
}

.banner img {
    height:200px ;
    margin-right: 50px;
}
.banner h1 {
    font-family: 'Noto Sans';
}