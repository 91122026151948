.aboutUs {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin-left: 100px;
    margin-right: 100px;
}
.aboutUs h1 {
    font-size: 40px;
}
.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

}
.imageHolder{
    position: relative;
    overflow: hidden;
    height: 70vh;
    aspect-ratio: 1;
    border-top-left-radius: 70px;
    border-bottom-right-radius: 70px;
    box-shadow: 10px 10px 6px 0px rgba(0,0,0,0.1);
}
.imageHolder img {
    position: relative;
    z-index: 1;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.aboutContent p {
    text-align: justify;
    font-family: 'Noto Sans';
    font-size: 29px;
    line-height: 150%;
}

.imageBackdrop {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
width: 100%;
height: 100%;
    background-image: linear-gradient(to right, #00000000,#ffffff7f);
}