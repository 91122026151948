.card {
    position: relative;
border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;

    border: 2px solid white;
    height: 350px;
    width: 90%;
    margin: 60px 0;
}
.card img {    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.decoLeft {
    display: flex;
    justify-content: center;
    font-size: 42px;
position: absolute;
border: 2px solid white;
width: 50px;
height: 50px;
bottom: -54px;
left: -54px;
border-top-left-radius: 50%;
border-bottom-right-radius: 50%;
}
.decoRight {
    display: flex;
    justify-content: center;
    font-size: 42px;
position: absolute;
border: 2px solid white;
width: 50px;
height: 50px;
top: -54px;
right: -54px;
border-top-left-radius: 50%;
border-bottom-right-radius: 50%;
}

.backdrop {
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    position: absolute;
    cursor: pointer;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#00000001,#270B2A);
    transition: "opacity" 300ms linear;
}

.coverFill{

    background-color: #020002;
    opacity: .8;
    background-image: none;
}
.card .content {
    position: absolute;
    bottom: 0;
    z-index: 7;
    left: 20px;
    width: 100%;
}
.card p {
    transition: all .3s linear;
    z-index: 7;
    width: 90%;
    font-family: 'Noto Sans';
    font-size: 22px;
}