.container {
    position: relative;
    overflow-x: hidden;
}
.container .backGrad {
    z-index: -2;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
    background-image: linear-gradient( #230D25,#4C0153);
    
}
.backdesign {
    position: fixed;
    top: 0;
    right: -90px;
    transform: rotate(-30deg);
    opacity: .4;
    z-index: -1;
}
.gallery {
    padding: 100px 100px;

}
.images{
        display: flex;
    flex-direction: column;
    align-items: center;

}
.card {
    position: relative;
border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;

    border: 2px solid white;
    height: 200px;
    width: 80%;
    margin: 60px 0;
}
.card img {    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.decoLeft {
    display: flex;
    justify-content: center;
    font-size: 42px;
position: absolute;
border: 2px solid white;
width: 50px;
height: 50px;
bottom: -54px;
left: -54px;
border-top-left-radius: 50%;
border-bottom-right-radius: 50%;
}
.decoRight {
    display: flex;
    justify-content: center;
    font-size: 42px;
position: absolute;
border: 2px solid white;
width: 50px;
height: 50px;
top: -54px;
right: -54px;
border-top-left-radius: 50%;
border-bottom-right-radius: 50%;
}

.backdrop {
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    position: absolute;
    cursor: pointer;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#00000001,#270B2A);
    transition: "opacity" 300ms linear;
}

.backdrop:hover{

    background-color: #020002;
    opacity: .8;
    background-image: none;
}
.card p {
    position: absolute;
    bottom: 0;
    z-index: 7;
    left: 20px;
    font-family: 'Noto Sans';
    font-size: 22px;
}