
.footer{
    width: 100%;
    padding: 30px 0;
    position: relative;
    background-color: #270B2A;
}


.content{
    position: relative;
    z-index: 5;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
}
.logo img{
    width: 190px;
    margin: 10px;
}
.logo p {
    
    font-size: 10px;
}
.contact img{
    height: 30px;
    margin: 0;
}
.logo{
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.logo hr{
    width: 80%;
}
.logo p{
    margin-top: 20px;
}
.links{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.links *{
    text-decoration: none;
    margin-bottom: 10px;
}

.links a{
    color: white;
}
.contact {
    display: flex;
    align-items: center;
    justify-content: end;
}
.contactAdd {
    display: flex;
    flex-direction: column;
   justify-content: center;
   width: 60%;
}
.iconLinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.contact h3{
    font-size: 14px;
    color: white;
    margin: 0;
}
.contact h1 {
    margin: 0;
}

a {
    text-decoration: none;
}
.devs {
    font-size: 12px;
    background-color: black;
    padding: 2px 0;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}